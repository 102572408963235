<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="px-0-print container-fluid text-dark">
                    <div class="row px-1 py-3 mb-1 no-print">
                        <label class="mt-2 ms-2 me-3 mb-2"> From: </label>
                        <input type="date" class="form-control col-sm-2" v-model="from" @change="getData()">
                        <label class="mt-2 ms-2 me-3 mb-2"> To: </label>
                        <input type="date" class="form-control col-sm-2" v-model="to" @change="getData()">
                        

                        <label class=" ms-6 mt-2 me-3"> Project: </label>
                        <select v-model="work_project_id" class="form-control col-sm-2 mb-2 px-1">
                            <option value=""></option>
                            <option v-for="project in work_projects" :key="project.work_project_id" v-bind:value="project.work_project_id"> {{ project.work_project_name }} </option>
                        </select>
                    </div>

                    <div class="row px-1 pb-3 pt-0 mb-1 no-print">
                        <label class="mt-2 col-1 "> Staffs: </label>
                        <select v-model="staff_id" class="form-control col-sm-2 mb-2" >
                            <option value=""></option>
                            <option v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs)" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                        </select>
                        <span class="col-1">
                            <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                        </span>

                        <label class=" ms-6 mt-2 me-3"> Employee: </label>
                        <select v-model="emp_id" class="form-control col-sm-2 mb-2 px-1" >
                            <option value=""></option>
                            <option v-for="employee in employees" :key="'ee' + employee.emp_id" v-bind:value="employee.emp_id"> {{ employee.full_name }} </option>
                        </select>


                        <button class="btn btn-success ms-4 col-1" style="height: 38px;" @click="getData()">
                            <i class="fa fa-search"></i>
                        </button>

                        <export-excel :data="excel_data" >
                            <button class="ms-4 btn btn-success no-print"> <v-icon class="mr-3" color="white"> mdi-microsoft-excel </v-icon> Download Excel </button>
                        </export-excel>
                    </div>
                    <div style="color: black; margin-top: 18px; margin-bottom: 18px;">
                        <span>Employee work projects from {{ from ? from : '' }} to {{ to ? to : '' }} </span>
                        <span style="margin-inline-start: 58px;">{{ staff_id ? 'Staff: ' + staffs.find(obj => obj.st_id == staff_id).staff_name : '' }}</span>
                        <span style="margin-inline-start: 58px;">{{ work_project_id ? 'Project: ' + work_projects.find(obj => obj.work_project_id == work_project_id).work_project_name : '' }}</span>
                        <span style="margin-inline-start: 58px;">{{ emp_id ? 'Employee: ' + employees.find(obj => obj.emp_id == emp_id).full_name : '' }}</span>
                    </div>
                    <table class="table table-bordered table-striped text-center " style="page-break-inside: always; font-size: 11pt !important; color: black;">
                        <thead>
                            <tr>
                                <th class="py-2">Employee</th>
                                <th class="py-2">Staff</th>
                                <th class="py-2">Project</th>
                                <th class="py-2">Working Days</th>
                                <th class="py-2">Daily Salary</th>
                                <th class="py-2">Monthly Salary</th>
                                <th class="py-2">Food</th>
                                <th class="py-2">Salary Per Month</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in (rows).filter(f => f.salary_type == 'Monthly')" :key="'rr' + index + '-' + new Date().getTime()">
                                <td>{{ row.employee_full_name }}</td>
                                <td>{{ row.staff_name }}</td>
                                <td>{{ row.work_project_name }}</td>
                                <td>{{ row.total_working_days_in_project }}</td>
                                <td>{{ (row.daily_salary).toLocaleString() }} $</td>
                                <td>{{ (row.monthly_salary).toLocaleString() }} $</td>
                                <td>{{ (row.food_money).toLocaleString() }} $</td>
                                <td>{{ (row.other_expense).toLocaleString() }} $</td>
                            </tr>
                            <tr v-for="(row, index) in (rows).filter(f => f.salary_type == 'Daily')" :key="'rr' + index + '-' + new Date().getTime()">
                                <td>{{ row.employee_full_name }}</td>
                                <td>{{ row.staff_name }}</td>
                                <td>{{ row.work_project_name }}</td>
                                <td>{{ row.total_working_days_in_project }}</td>
                                <td>{{ (row.daily_salary).toLocaleString() }} IQD</td>
                                <td>{{ (row.monthly_salary).toLocaleString() }} IQD</td>
                                <td>{{ (row.food_money).toLocaleString() }} IQD</td>
                                <td>{{ (row.other_expense).toLocaleString() }} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "../auth"
export default {
    name: "WorkProjectsReport",
    data() {
        return {
            staff_id: null,
            staffs: [],
            showSpecialStaffs: "false",
            work_projects: [],
            work_project_id: null,
            employees: [],
            emp_id: null,
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            from: new Date().toISOString().split('T')[0],
            to: new Date().toISOString().split('T')[0],
            rows: []
        }
    },
    beforeCreate() {
        auth('WorkProjectsReport')
    },
    created() {
        this.$http.post('/staff/getData').then(({data})=>{
            this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
        })
    },
    mounted() {
        this.$http.post('/work_projects/getData').then(r => {
            this.work_projects = r.data.filter(obj => obj.work_project_status == 'enabled')
            this.getData()
        })
    },
    computed: {
        excel_data() {
            const arr = []
            arr.push(...this.rows.filter(f => f.salary_type == 'Monthly').map(m => {
                return {
                    'Employee': m.employee_full_name,
                    'Staff': m.staff_name,
                    'Project': m.work_project_name,
                    'Working Days': m.total_working_days_in_project,
                    'Daily Salary': m.daily_salary,
                    'Monthly Salary': m.monthly_salary,
                    'Food': m.food_money,
                    'Salary Per Month': m.other_expense
                }
            }))

            arr.push(...this.rows.filter(f => f.salary_type == 'Daily').map(m => {
                return {
                    'Employee': m.employee_full_name,
                    'Staff': m.staff_name,
                    'Project': m.work_project_name,
                    'Working Days': m.total_working_days_in_project,
                    'Daily Salary': m.daily_salary,
                    'Monthly Salary': m.monthly_salary,
                    'Food': m.food_money,
                    'Salary Per Month': m.other_expense
                }
            }))

            return arr
        }
    },
    methods: {
        getData() {
            this.$http.post('/work_projects/report', {
                from: new Date(this.from).toISOString().split('T')[0],
                to: new Date(this.to).toISOString().split('T')[0],
                st_id: this.staff_id || null,
                work_project_id: this.work_project_id || null,
                emp_id: this.emp_id || null
            }).then(r => {
                this.rows = r.data
            })
        }
    },
    watch: {
        staff_id(value) {
            this.employees = []
            this.emp_id = null
            this.getData()
            if(value){
                this.$http.post("/employee/getEmployeeBystaff/" + this.staff_id + '/' + this.month + '/' + this.year).then(({data}) => {
                    this.employees = data
                });
            } else {
                this.employees = []
            }
        },
        month() {
            this.getData()
        },
        year() {
            this.getData()
        },
        work_project_id() {
            this.getData()
        },
        emp_id() {
            this.getData()
        }
    }
}
</script>

<style scoped>
    @media print {
        .no-print, #nav {
            display: none !important;
        }
        @page {
            size: portrait;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
                
        }
        #wrapper #content-wrapper {
            background-color: white !important;
        }
    }
</style>
